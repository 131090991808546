import React, { useEffect, useState } from "react";
import "./freeTrial.css";

const FreeTrial = ({ companyData }) => {
  const progressWidth = (companyData?.Subscription?.numberOfMinutes / 15) * 100;

  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });
  console.log(timeRemaining);

  useEffect(() => {
    const endDate = new Date(companyData?.Subscription?.endDate);
    console.log(endDate);

    const updateTimeRemaining = () => {
      const now = new Date();
      const diff = endDate - now; // Difference in milliseconds

      if (diff > 0) {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

        setTimeRemaining({ days, hours, minutes });
      } else {
        setTimeRemaining({ days: 0, hours: 0, minutes: 0 });
      }
    };

    // Update countdown immediately and set interval to update every minute
    updateTimeRemaining();
    const timerId = setInterval(updateTimeRemaining, 60000);

    // Clear interval on component unmount
    return () => clearInterval(timerId);
  }, [companyData?.Subscription?.endDate]);

  return (
    <div className="trial-notification">
      <div className="trial-content">
        <div className="d-flex flex-row align-items-center gap-3 justify-content-between mb-5">
          <p className="trial-text">
            You are currently enjoying a free trial of 15 minutes in 7 days of
            our <br />
            Speech-to-Text service.
          </p>
          <div className="time-boxes">
            <div className="time-box used-time">
              <p>Time used</p>
              <span>{companyData?.Subscription?.numberOfMinutes} min</span>
            </div>
            <div className="time-box remaining-time">
              <p>Time remaining</p>
              <span>
                {parseFloat(15) - companyData?.Subscription?.numberOfMinutes}{" "}
                min
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-3 justify-content-between">
          <div style={{ width: "85%" }}>
            <div className="progress-bar">
              <div
                className="progress"
                style={{
                  width: progressWidth + "%",
                }}
              ></div>{" "}
            </div>
            <p className="trial-remaining">
              Trial period remaining:{" "}
              <strong>
                {timeRemaining.days} days {timeRemaining.hours} hours{" "}
                {timeRemaining.minutes} minutes
              </strong>
            </p>
          </div>
          <button className="upgrade-button">Upgrade Now</button>
        </div>
      </div>
    </div>
  );
};

export default FreeTrial;
