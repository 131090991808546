import React from "react";
import "./expiredTrial.css";

const ExpiredTrial = () => {
  return (
    <div className="trial-expired-notification">
      <div className="expired-content">
        <p className="expired-text">
          Your 7-day trial has ended. Please recharge your account to continue
          using <br /> our Speech-to-Text service.
        </p>
      </div>
      <div className="d-flex align-items-center justify-content-between gap-3">
        <p className="expired-note">
          Service will be unavailable until your account is recharged.
        </p>
        <button className="upgrade-button expired-btn">Upgrade Now</button>
      </div>
    </div>
  );
};

export default ExpiredTrial;
